
                        import worker from "../../src/ts/perspective-server.worker.js";
                        function make_host(a, b) {
                            function addEventListener(type, callback) {
                                a.push(callback);
                            }

                            function removeEventListener(callback) {
                                const idx = a.indexOf(callback);
                                if (idx > -1) {
                                    a.splice(idx, 1);
                                }
                            }

                            function postMessage(msg) {
                                for (const listener of b) {
                                    listener({data: msg});
                                }
                            }

                            return {
                                addEventListener,
                                removeEventListener,
                                postMessage,
                                location: {href: ""}
                            }
                        }

                        function run_single_threaded(code) {
                            console.error("Running perspective in single-threaded mode");
                            let f = Function("const self = arguments[0];" + code);
                            const workers = [];
                            const mains = [];
                            f(make_host(workers, mains));
                            return make_host(mains, workers);
                        }

                        export const initialize = async function () {
                            try {
                                if (window.location.protocol.startsWith("file")) {
                                    console.warn("file:// protocol does not support Web Workers");
                                    return run_single_threaded(worker);
                                } else {
                                    const blob = new Blob([worker], {type: 'application/javascript'});
                                    const url = URL.createObjectURL(blob);
                                    return new Worker(url, {type: "module"});
                                }
                            } catch (e) {
                                console.error("Error instantiating engine", e);
                            }
                        };

                        export default initialize;
                    